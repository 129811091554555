import React from "react"
import { navigate } from "gatsby"
import AboutImgOne from "../../images/about1.png"
import AboutImgTwo from "../../images/about2.png"
import LeftCircleImg from "../../images/left-circle.png"
import RightCircleImg from "../../images/right-circle.png"
import SectionTitle from "../SectionTitle"
import ButtonTransparent from "../UI/ButtonTransparent"
import Solution from "./Solution"

const SolutionHighlights = () => {
  return (
    <>
      <section className="relative body-font w-full pt-10 pb-3 md:pb-20 overflow-hidden">
        <img
          className=" hidden lg:block 
                          absolute
                          top-[10px]
                          -left-[30px]
                          z-[2]
                      "
          src={LeftCircleImg}
        />
        <img
          className=" hidden lg:block 
                          absolute
                          bottom-[10px]
                          -right-[30px]
                          z-[2]
                      "
          src={RightCircleImg}
        />
        <div className="container px-4 max-w-6xl mx-auto items-center flex flex-wrap">
          <SectionTitle
            titleHead="Our Products"
            title="A simple solution for a"
            spanTag="Complex Problem"
          />
          <Solution
            title="Sherpa"
            paragraph={`Our proprietary Project Execution tool. Sherpa utilizes static and dynamic
            processes/features to make the real-estate execution process more
            efficient, predictable and transparent for all project stakeholders. These
            processes include a notification system, machine learning based
            recommendations and the automatic assignment of project Tasks,
            Deliverables and Milestones. Sherpa is utilized from project identification
            through project turnover. With a focus on the overall development
            process, Sherpa is used by owners, service providers and all other project
            stakeholders.`}
            button={
              <ButtonTransparent
                btnText="View Details"
                onClick={() => navigate("/products/sherpa")}
              />
            }
            smallImg={AboutImgTwo}
            bigImg={AboutImgOne}
            order="ltr"
          />
          <Solution
            title="Rover"
            paragraph="Rover systemizes the prospecting process. These prospects can be
            potential projects, partners or clients. With Rover, the appropriate team
            members and vendors have access to the necessary Tasks, Deliverables
            and Milestones required to fully evaluate an opportunity. In addition to
            allowing multiple stakeholders to be engaged in evaluating an opportunity,
            Rover allows for the standardization of opportunity due diligence. Given
            that opportunities come from disparate sources, Rovers single interface,
            allows for an accurate summary of all opportunities in addition to the
            ability to perform different types of data analysis."
            button={
              <ButtonTransparent
                btnText="View Details"
                onClick={() => navigate("/products/rover")}
              />
            }
            smallImg={AboutImgTwo}
            bigImg={AboutImgOne}
            order="rtl"
          />
        </div>
      </section>
    </>
  )
}

export default SolutionHighlights
