import React from "react"

const Solution = ({
  title,
  paragraph,
  button,
  smallImg,
  bigImg,
  order,
}: {
  title: string
  paragraph: string
  button: JSX.Element
  smallImg: string
  bigImg: string
  order: string
}) => {
  return (
    <>
      {order === "ltr" && (
        <div className="flex flex-wrap items-center justify-between max-w-2xl lg:max-w-full mb-12 ">
          <div className="w-full lg:w-1/2 mb-4 lg:mb-0 order-2 md:order-1 mt-10 md:mt-0" data-aos="fade-up">
            <h4 className="text-xl md:text-2xl font-semibold mb-5">{title}</h4>
            <p className="leading-relaxed text-sm">{paragraph}</p>
            {button}
          </div>
          <div className="w-full mt-32 md:mt-0 lg:w-1/2 lg:pl-16 relative order-1 md:order-2" data-aos="fade-up">
            <img
              className="bg-white rounded shadow-lg rounded-lg absolute right-[0px] -top-[100px] 
              animate-none md:ltr-animation "
              src={smallImg}
            />
            <img
              className="bg-white rounded shadow-lg rounded-lg "
              src={bigImg}
            />
          </div>
        </div>
      )}
      {order === "rtl" && (
        <div className="flex flex-wrap items-center justify-between max-w-2xl lg:max-w-full mb-3 md:mb-12 pt-20 md:pt-40">
          <div className="w-full lg:w-1/2 lg:pr-16 relative order-1" data-aos="fade-up">
            <img
              className="bg-white rounded shadow-lg rounded-lg absolute left-[0px] -top-[100px] 
              animate-none md:ltr-animation"
              src={smallImg}
            />
            <img
              className="bg-white rounded shadow-lg rounded-lg "
              src={bigImg}
            />
          </div>
          <div className="w-full lg:w-1/2 mt-10 md:mt-0 mb-2 lg:mb-0 order-2" data-aos="fade-up">
            <h4 className="text-xl md:text-2xl font-semibold mb-5">{title}</h4>
            <p className="leading-relaxed text-sm">{paragraph}</p>
            {button}
          </div>
        </div>
      )}
    </>
  )
}

export default Solution
