import React from "react"

const Banner = ({
  title,
  titleTag,
  subTitle,
  subTag,
  img,
  btn,
}:{
  title: string
  titleTag: string
  subTitle?: string
  subTag?: string
  img: string
  btn?: JSX.Element
}) => {
  return (
    <>  
          <div className="mx-auto max-w-7xl pb-10 pt-10 sm:pb-32 lg:flex lg:py-10">
            <div
              className="
              mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8
                      "
            >
              <div className="my-3">
                <h1
                  data-aos="fade-up"
                  className="leading-tight md:leading-tight text-3xl md:text-5xl font-bold mb-5 text-textColor"
                >
                  {title}
                  <span className="text-emerald-800 ml-1 block">{titleTag}</span>
                </h1>
                    {subTitle && 
                      <p
                        className="mb-7 md:mt-15 mt-10 text-base md:text-xl font-medium"
                        data-aos="fade-up"
                      >
                        {subTitle}
                        <span className="text-yellow-500 ml-1 inline-block">{subTag}</span>
                      </p>
                    }
                {btn}
              </div>
            </div>
            <div
              className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32"
              data-aos="fade-up"
            >

              <div className="max-w-3xl flex-none sm:max-w-3xl lg:max-w4xl">
                  <img className="rounded-md w-full md:w-auto" src={img} />
              </div>
            </div>
          </div>
    </>
  )
}

export default Banner
