import * as React from "react"
import productbg2Img from "../../images/productbg2.png"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Banner from "../../components/Banner/BannerMain"
import SolutionHighlights from "../../components/SolutionHighlights"
import DemoBookingSection from "../../components/DemoBookingSection"

const Products = () => (
  <Layout>
    <Seo title="Products" />
    <section
      id="home"
      className="header bg-emerald-100 relative  items-center flex w-full"
    >
      <div className="container px-4  max-w-6xl mx-auto items-center flex flex-wrap">
        <Banner
          title="Transform your Business the your way"
          titleTag="Wide range of Solution"
          subTitle="Simple & Affordable System for your Business"
          subTag="SHERPA | ROVER"
          img={productbg2Img}
        />
      </div>
    </section>
    <SolutionHighlights />
    <DemoBookingSection />
  </Layout>
)

export default Products
