import * as React from "react"

const SectionTitle = ({
  titleHead,
  title,
  spanTag,
  subTitle,
  customClass,
}: {
  titleHead: string
  title: string
  spanTag: string
  subTitle?: string
  customClass?: string
}) => (
  <>
    <div className={`${customClass} mb-5 w-full md:w-1/2`} data-aos="fade-up">
      <span className="text-xs font-semibold inline-block py-2 px-3 rounded-full text-emerald-800 bg-emerald-100  uppercase">
        {titleHead}
      </span>
      <h1 className="sm:text-3xl text-2xl font-bold text-textColor">
        {title}
        <span className="text-emerald-800 ml-1 inline-block">{spanTag}</span>
      </h1>
      {subTitle && <p>{subTitle}</p>}
    </div>
  </>
)

export default SectionTitle
