import React from "react"
import * as ButtonStyles from "./ButtonTransparent.module.css"

const ButtonTransparent = ({
  btnText,
  onClick,
}: {
  btnText: string
  onClick: () => void
}) => {
  return (
    <>
      <button className={ButtonStyles.btn_rounded} onClick={onClick}>
        <span className=" inline-flex items-center">
          {btnText}
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-4 h-4 ml-1"
            viewBox="0 0 24 24"
          >
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </span>
      </button>
    </>
  )
}

export default ButtonTransparent
