import React from "react"
import DashboardUI from "../../images/dashboardUI.png"
import dashboardTiltImg from "../../images/dashboardTilt.png"
import { navigate } from "gatsby"

const DemoBookingSection = () => {
  return (
    <>
      <section
        className="relative body-font w-full pt-10 pb-2 md:pb-8"
        style={{
          backgroundImage: `url(${dashboardTiltImg})`,
          backgroundSize: "100% 65%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container px-4 max-w-6xl mx-auto items-center flex flex-wrap">
          <div className="text-center  mb-10 w-full ">
            <button data-aos="fade-up" 
              className="bg-emerald-800 text-white active:bg-emerald-400 
                            text-xs font-bold px-7 py-3 rounded-full 
                            shadow hover:bg-emerald-600 hover:shadow-lg 
                            outline-none ease-linear transition-all duration-300  
                            mb-3 inline-block"
              onClick={() =>
                navigate(
                  "https://e9a62902.sibforms.com/serve/MUIEAO8kZ1FO6AMtrQLW6oup7rKCCyHNd1q4JOaNGHgph3Ust7rn1xnfnKi1f6n9JPnPtPctUpExZJUTB5r7Y0Z0uKLgsEFijiNazJX_VolvhZK0xXgcivR2fOD3VmfjmdKPBhieVN4U_ZLwG8YP7Q6qeS-gbuvjlxS2bBDWFZuP5CI9eZy6vc9t6oUlAr-I-Y64CLEAJoRnSENH"
                )
              }
            >
              Schedule a Demo
            </button>
            <h2 className="sm:text-3xl text-2xl font-bold text-textColor" data-aos="fade-up" >
              Bring your real-estate 
              <span className="text-emerald-800 mx-1 inline-block">
              into the future
              </span>
            </h2>
          </div>
          <div className="flex flex-wrap w-full mb-2 md:mb-12">
            <div className="w-full" data-aos="fade-up" >
              <img
                className="md:w-4/6 w-3/6 m-auto mb-2 object-cover object-center rounded"
                alt="hero"
                src={DashboardUI}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default DemoBookingSection
